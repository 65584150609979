import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import { ENV, RELEASE, SENTRY_DNS } from './config';

if (ENV === 'production') {
	Sentry.init({
		dsn: SENTRY_DNS,
		integrations: [new Sentry.Replay()],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		release: RELEASE,
	});
}
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
