import { useDialog } from '@/context/DialogProvider';
import { CenteredModal, Drawer } from '../Modal';
import { ConfirmDialog } from './ConfirmDialog';
import { DeleteDialog } from './DeleteDialog';
import { StopDialog } from './StopDialog';
import { cloneElement } from 'react';

export const DialogRenderer = () => {
	const { dialogType, dialogData, closeDialog } = useDialog();

	if (dialogType === 'deleteConfirmation') {
		return (
			<div>
				<DeleteDialog
					show={dialogType === 'deleteConfirmation'}
					setShow={() => closeDialog(false)}
					title={dialogData.title}
					description={dialogData.description}
					closeDialog={closeDialog}
				/>
			</div>
		);
	} else if (dialogType === 'confirm') {
		return (
			<ConfirmDialog
				show={dialogType === 'confirm'}
				setShow={() => closeDialog(false)}
				title={dialogData.title}
				description={dialogData.description}
				closeDialog={closeDialog}
			/>
		);
	} else if (dialogType === 'stop') {
		return (
			<div>
				<StopDialog
					show={dialogType === 'stop'}
					setShow={() => closeDialog(false)}
					title={dialogData.title}
					description={dialogData.description}
					closeDialog={closeDialog}
				/>
			</div>
		);
	} else if (dialogType === 'custom' && dialogData.type !== 'drawer') {
		if (dialogData.withoutModel) {
			const ele = cloneElement(dialogData.component, {
				setShow: () => closeDialog(false),
				show: dialogType === 'custom',
				...(dialogData.width || { width: dialogData.width }),
				...dialogData.props,
			});
			return <>{ele}</>;
		}
		return (
			<CenteredModal
				show={dialogType === 'custom'}
				setShow={() => closeDialog(false)}
				width={dialogData.width}
			>
				{dialogData.component}
			</CenteredModal>
		);
	} else if (dialogType === 'custom' && dialogData.type === 'drawer') {
		return (
			<Drawer show={dialogType === 'custom'} setShow={() => closeDialog(false)}>
				{dialogData.component}
			</Drawer>
		);
	} else {
		return null;
	}
};
