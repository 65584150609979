import { createClient, configureChains } from 'wagmi';
import { avalanche, bsc, mainnet } from '@wagmi/core/chains';

import { publicProvider } from 'wagmi/providers/public';
import { infuraProvider } from 'wagmi/providers/infura';

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

const projectId = '905aa6227c154b23fb01f6f90f3065c8';
const infuraId = '6e8fc37e515549f89946ac79c4f050cc';

export const { chains, provider, webSocketProvider } = configureChains(
	[mainnet, avalanche, bsc],
	[
		infuraProvider({
			apiKey: infuraId,
		}),
		publicProvider(),
	],
);

export const wagmiClient = createClient({
	autoConnect: true,
	connectors: [
		new MetaMaskConnector({
			chains,
			options: {
				shimDisconnect: true,
				UNSTABLE_shimOnConnectSelectAccount: true,
			},
		}),
		new CoinbaseWalletConnector({
			chains,
			options: {
				appName: 'wagmi',
			},
		}),
		new WalletConnectConnector({
			chains,
			options: {
				projectId,
				showQrModal: true,
				qrModalOptions: {
					themeVariables: {
						'--w3m-z-index': '100000000',
					},
				},
			},
		}),
		// new InjectedConnector({
		// 	chains,
		// 	options: {
		// 		name: 'Injected',
		// 		shimDisconnect: true,
		// 	},
		// }),
	],
	provider,
	webSocketProvider,
});
