export const getItemFromLocalStorage = (key: string): string | null => {
	if (typeof window === 'undefined') {
		return null;
	}
	try {
		const item = window.localStorage.getItem(key);

		return JSON.parse(item) ? JSON.parse(item) : null;
	} catch (error) {
		return null;
	}
};

export const setItemToLocalStorage = (key: string, value: any): void => {
	if (typeof window === 'undefined') {
		return;
	}
	try {
		window.localStorage.setItem(key, JSON.stringify(value));
	} catch (error) {
		console.log(error);
	}
};
