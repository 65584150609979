/** @format */

import clsx from 'clsx';

interface ButtonProps {
	loading?: boolean;
	variant?: keyof typeof variants;
	color?: keyof typeof colors;
	startIcon?: string;
	endIcon?: string;
	size?: string;
	className?: string;
	type?: 'button' | 'submit' | 'reset';
	children?: React.ReactNode;
	pill?: boolean;
	block?: boolean;
	onClick?: () => void | Promise<void>;
	link?: string;
	disabled?: boolean;
}

const colors = {
	primary: 'primary',
	danger: 'danger',
	secondary: 'secondary',
	success: 'success',
	warning: 'warning',
	info: 'info',
	light: 'light',
	dark: 'dark',
	white: 'white',
};

const variants = {
	ghost: 'ghost',
	soft: 'soft',
	outline: 'outline',
};

export const Button: React.FC<ButtonProps> = ({
	loading,
	variant,
	color,
	startIcon,
	disabled,
	endIcon,
	size,
	className,
	type = 'button',
	children,
	pill,
	block,
	onClick,
	link,
	...props
}) => {
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if (onClick) {
			onClick();
			return;
		}
		link && window.open(link, '_blank');
	};

	return (
		<button
			type={type}
			disabled={loading || disabled}
			onClick={handleClick}
			className={clsx(
				'btn',
				'btn-' + (variant ? variants[variant] + '-' : '') + (color || colors.primary),
				size && 'btn-' + size,
				block && 'w-100',
				pill && 'rounded-pill',
				className,
				'd-inline-flex align-items-center justify-content-center',
			)}
			{...props}
		>
			{loading && <span className="spinner-border spinner-border-sm me-2"></span>}
			{!loading && startIcon && <i className={clsx(startIcon, 'me-2')}></i>}
			{children}
			{!loading && endIcon && <i className={clsx(endIcon, 'ms-2')}></i>}
		</button>
	);
};
