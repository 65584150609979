import clsx from 'clsx';
import { Button } from '@/components';

export const StopDialog = ({ show, setShow, title, description, closeDialog }) => {
	return (
		<div>
			<div
				className={clsx('modal modal-sm fade', show && 'show d-block')}
				role="dialog"
				onClick={() => setShow(false)}
			>
				<div
					className="modal-dialog modal-dialog-centered"
					role="document"
					onClick={() => setShow(false)}
				>
					<div className="modal-content" onClick={(e) => e.stopPropagation()}>
						<div className="modal-body">
							<div
								className={clsx(
									'avatar avatar-circle',
									`avatar-soft-danger border`,
									`border-danger`,
								)}
							>
								<span className="avatar-initials">
									<i className={`fs-1 bi-slash-circle-fill`}></i>
								</span>
							</div>
							<div className="h5 mt-3">{title}</div>
							<div className="">{description}</div>
							<div className="d-flex mt-3">
								<Button
									className="me-2"
									size="sm"
									block
									variant="soft"
									onClick={() => closeDialog(false)}
									color="danger"
								>
									No
								</Button>
								<Button
									size="sm"
									block
									onClick={() => closeDialog(true)}
									color="danger"
								>
									Yes, Stop
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{show && (
				<div
					className={clsx('modal-backdrop fade', show && 'show')}
					style={{ backdropFilter: 'blur(15px)' }}
				></div>
			)}
		</div>
	);
};
