import autoAnimate from '@formkit/auto-animate';
import { useEffect, useRef } from 'react';

export const useAnimate = () => {
	const ref = useRef();

	useEffect(() => {
		ref.current &&
			autoAnimate(ref.current, {
				duration: 300,
				easing: 'ease-out',
			});
	}, []);

	return { ref };
};
