import { createContext, useContext, useState } from 'react';

type DialogType =
	| 'deleteConfirmation'
	| 'paywall'
	| 'stop'
	| 'reward'
	| 'custom'
	| 'confirm'
	| null;

let resolveCallback: (response: boolean) => void;

interface DialogContextType {
	showDialog: (type: DialogType, data?: any) => Promise<boolean>;
	closeDialog: (response: boolean) => void;
	dialogType: DialogType;
	dialogData: any;
}

const DialogContext = createContext<DialogContextType>({
	showDialog: async () => {
		return true;
	},
	closeDialog: () => {},
	dialogType: null,
	dialogData: null,
});

export const DialogProvider = ({ children }: { children: JSX.Element[] }) => {
	const [dialogType, setDialogType] = useState<DialogType>(null);
	const [dialogData, setDialogData] = useState<any>(null);

	const showDialog = (type: DialogType, data?: any): Promise<boolean> => {
		setDialogType(type);
		setDialogData(data);
		return new Promise((res) => {
			resolveCallback = res;
		});
	};

	const closeDialog = (value: boolean) => {
		setDialogType(null);
		setDialogData(null);
		resolveCallback(value);
	};

	return (
		<DialogContext.Provider value={{ showDialog, closeDialog, dialogType, dialogData }}>
			{children}
		</DialogContext.Provider>
	);
};

export const useDialog = () => useContext(DialogContext);
