import { Navigate } from 'react-router-dom';
import { lazyImport } from '@/utils/lazyImport';

const { VerifyRoutes } = lazyImport(() => import('@/features/Verification'), 'VerifyRoutes');
const { BaseRoute } = lazyImport(() => import('@/features/Base'), 'BaseRoute');

export const publicRoutes = [
	{
		path: '/*',
		element: <BaseRoute />,
	},
	{
		path: '/verify/*',
		element: <VerifyRoutes />,
	},
	{ path: '*', element: <Navigate to="/" /> },
];
