import { useLockBodyScroll } from '@/hooks';
import clsx from 'clsx';

interface DrawerProps {
	show: boolean;
	setShow: (show: boolean) => void;
	children: React.ReactNode;
	divider?: boolean;
	title?: string;
	description?: string;
	minWidth?: string;
	footer?: React.ReactNode;
}

export const Drawer = ({
	show,
	setShow,
	children,
	divider,
	title,
	description,
	minWidth,
	footer,
}: DrawerProps) => {
	useLockBodyScroll(show);
	return (
		<>
			<div
				className={clsx(`offcanvas offcanvas-end`, show && 'show')}
				style={{ minWidth: minWidth ? minWidth : '50vw' }}
			>
				<div className="position-absolute end-0 m-3">
					<button
						type="button"
						className="btn-close"
						onClick={() => setShow(false)}
					></button>
				</div>

				<div className="offcanvas-body">
					{title && <h2 className="">{title}</h2>}
					{description && <p className="">{description}</p>}
					{divider && <div className="divider mx-n5  mb-5"></div>}
					{children}
				</div>
				{footer && <div className="offcanvas-footer">{footer}</div>}
			</div>
			<div
				className={clsx('modal-backdrop fade', show && 'show')}
				onClick={() => setShow(false)}
				style={{ backdropFilter: 'blur(5px)' }}
			></div>
		</>
	);
};
