import { Confetti } from '@/components/Elements';
import { createContext, useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocalStorage } from '@/hooks/index';
import { ThemeType } from '@/definitions/index';

interface ThemeContextType {
	theme: ThemeType;
	isDark: boolean;
	toggleNavbar: () => void;
	selectTheme: (theme: ThemeType) => void;
	themeColors: any;
	celebrate: () => void;
}

const ThemeContext = createContext<ThemeContextType>({
	theme: ThemeType.LightMode,
	isDark: false,
	toggleNavbar: () => {},
	selectTheme: () => {},
	themeColors: {},
	celebrate: () => {},
});

export const ThemeProvider = ({ children }: { children: JSX.Element }) => {
	const [theme, setTheme] = useLocalStorage<ThemeType>('themeMode', ThemeType.LightMode);
	const [open, setOpen] = useLocalStorage('sidebar', true);
	const [showConfetti, setShowConfetti] = useState(false);

	const celebrate = () => {
		setShowConfetti(true);
		setTimeout(() => {
			setShowConfetti(false);
		}, 5000);
	};

	const themeColors = useMemo(() => {
		const lightTheme = {
			primary: '#377dff',
			secondary: '#71869d',
			success: '#00c9a7',
			info: '#09a5be',
			warning: '#f5ca99',
			danger: '#ed4c78',
			light: '#f9fafc',
			dark: '#132144',
			white: '#fff',
			borderWidth: '0.0625rem',
			border: 'rgba(231, 234, 243, 0.7)',
		};

		const darkTheme = {
			primary: '#377dff',
			secondary: '#6d747b',
			success: '#00c9a7',
			info: '#09a5be',
			warning: '#f5ca99',
			danger: '#ed4c78',
			light: '#2c2f32',
			dark: '#fff',
			darkInput: '#25282a',
			borderWidth: '0.0625rem',
			border: '#2f3235',
		};

		return theme === 'light' ? lightTheme : darkTheme;
	}, [theme]);

	const selectTheme = (theme: ThemeType) => {
		setTheme(theme);
	};

	const getTheme = () => {
		if (theme === ThemeType.LightMode) {
			return '/assets/css/theme.css';
		}
		if (theme === ThemeType.DarkMode) {
			return '/assets/css/theme-dark.css';
		}
		if (theme === ThemeType.SystemMode) {
			const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)').matches;
			if (darkThemeMq) {
				return '/assets/css/theme-dark.css';
			} else {
				return '/assets/css/theme.css';
			}
		}
		return '/assets/css/theme.css';
	};

	return (
		<ThemeContext.Provider
			value={{
				theme,
				selectTheme,
				isDark: theme === ThemeType.DarkMode,
				toggleNavbar: () => setOpen(!open),
				themeColors,
				celebrate,
			}}
		>
			<Helmet
				link={[
					{
						rel: 'stylesheet',
						type: 'text/css',
						href: window.location.origin + '/assets/css/theme.css',
					},
					{
						rel: 'stylesheet',
						type: 'text/css',
						href: window.location.origin + getTheme(),
					},
				]}
				style={[
					{
						cssText: `
                            [data-hs-theme-appearance]:not([data-hs-theme-appearance='${
								theme === ThemeType.DarkMode ? 'dark' : 'default'
							}']) {
                                display: none!important;
                            }
                        `,
					},
				]}
			/>
			{children}
			{showConfetti && <Confetti />}
		</ThemeContext.Provider>
	);
};

export const useTheme = () => useContext(ThemeContext);
