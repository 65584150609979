import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/lib/react-query';
import { DialogProvider } from '@/context/DialogProvider';
import { ThemeProvider } from '@/context/ThemeProvider';
import { ErrorFallback, FullPageLoading, DialogRenderer } from '@/components/index';
import { TrackingProvider } from '@/context/TrackingProvider';
import { WagmiConfig } from 'wagmi';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { wagmiClient } from '@/lib/wagmi';

export const AppProvider = ({ children }: { children: JSX.Element }) => {
	return (
		<Router>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<HelmetProvider>
					<TrackingProvider>
						<QueryClientProvider client={queryClient}>
							<ReactQueryDevtools initialIsOpen={true} />
							<WagmiConfig client={wagmiClient}>
								<ThemeProvider>
									<Suspense fallback={<FullPageLoading />}>
										<DialogProvider>
											{children}

											<div></div>
											<DialogRenderer />
											<Toaster />
										</DialogProvider>
									</Suspense>
								</ThemeProvider>
							</WagmiConfig>
							<ReactQueryDevtools />
						</QueryClientProvider>
					</TrackingProvider>
				</HelmetProvider>
			</ErrorBoundary>
		</Router>
	);
};
