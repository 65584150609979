/** @format */

import clsx from 'clsx';
import { useAnimate, useLockBodyScroll } from '@/hooks';
import React, { FC } from 'react';

interface Props {
	show: boolean;
	setShow: React.Dispatch<React.SetStateAction<boolean>>;
	imageTop?: string;
	width?: string;
	title?: string;
	size?: 'sm' | 'lg' | 'xl';
	footer?: React.ReactNode;
	children?: React.ReactNode;
	styles?: React.CSSProperties;
	className?: string;
}

export const CenteredModal: FC<Props> = ({
	show,
	setShow,
	imageTop,
	width,
	title,
	size,
	footer,
	children,
	styles,
	className,
}) => {
	const { ref } = useAnimate();
	useLockBodyScroll(show);

	return (
		<>
			<div
				className={clsx('modal fade', show && 'show d-block', size && 'modal-' + size)}
				tabIndex={-1}
				role="dialog"
				onClick={() => setShow(false)}
				style={styles}
			>
				<div
					className={`modal-dialog  modal-dialog-centered `}
					role="document"
					onClick={() => setShow(false)}
					style={width ? { minWidth: width } : {}}
				>
					<div
						className={`modal-content position-relative shadow-lg ${className}`}
						ref={ref}
						onClick={(e) => e.stopPropagation()}
					>
						{show && (
							<div>
								{title && (
									<div className="modal-header border-bottom pb-3 mt-n2">
										<h5 className="modal-title h3 mb-0">{title}</h5>
										<div
											className="btn-xs btn-close pointer"
											onClick={() => setShow(false)}
										></div>
									</div>
								)}
								{!title && setShow && (
									<div
										className="position-absolute end-0 p-4"
										style={{ zIndex: '1100000' }}
									>
										<div
											className="btn-xs btn-close pointer bg-white p-2"
											onClick={() => setShow(false)}
										></div>
									</div>
								)}
								{imageTop && (
									<img
										src={imageTop}
										className="card-img-top rounded-1"
										style={{}}
										alt="a"
									/>
								)}
								<div className="modal-body">{children}</div>
								{footer && <div className="modal-footer">{footer}</div>}
							</div>
						)}
					</div>
				</div>
			</div>
			{show && (
				<div
					className={clsx('modal-backdrop fade', show && 'show')}
					style={{ backdropFilter: 'blur(15px)' }}
				></div>
			)}
		</>
	);
};

export default CenteredModal;
