export enum ThemeType {
	LightMode = 'light',
	DarkMode = 'dark',
	SystemMode = 'system',
}

export enum DashboardViewType {
	Full = 'full',
	Compact = 'miniView',
	Closed = 'closedView',
}

export enum ScreenType {
	Lg = 100,
	Md = 200,
}

export enum ErrorTypes {
	IncorrectEmail = 'INCORRECT_EMAIL',
	IncorrectPassword = 'INCORRECT_PASSWORD',
	UserAlreadyExists = 'USER_ALREADY_EXISTS',
	NoCodeProvided = 'NO_CODE_PROVIDED',
	InvalidHash = 'INVALID_HASH',
	UserNotFound = 'USER_NOT_FOUND',
	InvalidToken = 'INVALID_TOKEN',
	IncorrectChain = 'INCORRECT_CHAIN',
	IncorrectTokenType = 'INCORRECT_TOKEN_TYPE',
	InvalidTelegram = 'INVALID_TELEGRAM',
	LoggedOut = 'LOGGED_OUT',
	MissingFields = 'MISSING_FIELDS',
	MissingHeaders = 'MISSING_HEADERS',
	InvalidFormBody = 'INVALID_FORM_BODY',
	InvalidCode = 'INVALID_CODE',
	AccessMissing = 'ACCESS_MISSING',
	ResourceMissing = 'RESOURCE_MISSING',
	AlreadyIntegrated = 'ALREADY_INTEGRATED',
	ResourceAlreadyExists = 'RESOURCE_ALREADY_EXISTS',
	ServiceIssue = 'SERVICE_ISSUE',
	UnknownMember = 'UNKNOWN_MEMBER',
	MissingPermissions = 'MISSING_PERMISSIONS',
	TypesMismatch = 'TYPES_MISMATCH',
	TypesMissing = 'TYPES_MISSING',
	ServerError = 'SERVER_ERROR',
	GeneralError = 'GENERAL_ERROR',
	ChannelPermissionIssue = 'CHANNEL_PERMISSION_ISSUE',
	TaskFailed = 'TASK_FAILED',
	TaskAlreadyDone = 'TASK_ALREADY_DONE',
	NotActive = 'NOT_ACTIVE',
	LoginError = 'LOGIN_ERROR',
	ReauthNeeded = 'REAUTH_NEEDED',
	Unauthorized = 'UNAUTHORIZED',
	InvalidCriteria = 'INVALID_CRITERIA',
	InvalidOperation = 'INVALID_OPERATION',
}

export enum ChainsIds {
	Ethereum = 1,
	Polygon = 137,
	BSC = 56,
	Avalanche = 43114,
}

export enum TrackingEvents {
	PageView = 'page_view',
	TwitterInit = 'twitter_init',
	TwitterInitError = 'twitter_init_error',
	TwitterRedirectInit = 'twitter_redirect_init',
	TwitterRedirectSuccess = 'twitter_redirect_success',
	TwitterRedirectFailed = 'twitter_redirect_failed',
	WalletInit = 'wallet__init',
	WalletInitError = 'wallet__init_error',

	WalletLoginInit = 'wallet_login_init',
	WalletLoginSuccess = 'wallet_login_success',
	WalletLoginError = 'wallet_login_error',
	WasOnBasePath = 'was_on_base_path',
}
