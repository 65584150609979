import { createContext, useContext, useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useLocalStorage, useRouter } from '@/hooks';
import { ENV, MIXPANEL_TOKEN } from '@/config';
import { TrackingEvents } from '@/definitions';

interface TrackingContextType {
	trackEvent: (eventName: string, eventProps?: any) => void;
	identifyUser: (userId: string) => void;
	loginUser: (userId: string, email: string, firstName: string, isAdmin: boolean) => void;
}

const TrackingContext = createContext<TrackingContextType>({
	trackEvent: () => {},
	identifyUser: () => {},
	loginUser: () => {},
});

export const TrackingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { location, query } = useRouter();
	const [toBeTracked, setToBeTracked] = useLocalStorage<any>('tracking', true);
	const [isInitialized, setIsInitialized] = useState(false);
	useEffect(() => {
		if (ENV === 'development' || ENV === 'dev') return;
		if (!toBeTracked) return;
		mixpanel.init(MIXPANEL_TOKEN, {
			api_host: 'https://api.mixpanel.com',
			debug: true,
		});
		setIsInitialized(true);
	}, []);

	const trackEvent = (eventName: string, eventProps: any) => {
		if (!isInitialized) return;
		mixpanel.track(eventName, eventProps);
	};

	const identifyUser = (userId: string) => {
		if (!isInitialized) return;
		mixpanel.identify(userId);
	};

	const loginUser = (userId: string, email: string, firstName: string, isSuperAdmin: boolean) => {
		if (email.includes('buzz') || email.includes('intract.io')) stopTracking();
		if (!isInitialized) return;
		mixpanel.alias(userId);
		mixpanel.people.set({
			$created: new Date(),
			$last_login: new Date(),
			$first_name: firstName,
			$email: email,
			isAdmin: isSuperAdmin,
		});
	};

	function replaceObjectIdsWithId(str: string) {
		const regex = /([a-fA-F\d]{24})/g;
		return str.replace(regex, 'id');
	}

	function stopTracking() {
		setToBeTracked(false);
	}

	useEffect(() => {
		if (!isInitialized) return;
		trackEvent(TrackingEvents.PageView, {
			path: replaceObjectIdsWithId(location.pathname),
		});
	}, [location, query]);

	return (
		<TrackingContext.Provider
			value={{
				trackEvent,
				identifyUser,
				loginUser,
			}}
		>
			{children}
		</TrackingContext.Provider>
	);
};

export const useTracking = () => useContext(TrackingContext);
