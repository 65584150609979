import { useLayoutEffect } from 'react';

export function useLockBodyScroll(show: boolean) {
	useLayoutEffect(() => {
		const originalStyle = window.getComputedStyle(document.body).overflow;
		if (show) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'visible';
		}
		return () => {
			document.body.style.overflow = originalStyle;
		};
	}, [show]);
}
